.form-switch.form-check-solid .form-check-input {
  height: 22px !important;
}
.activity .nav-line-tabs3 .nav-item .nav-link.active {
  background: linear-gradient(180deg, #00c9ff 0.24%, #0059e1 96.59%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 50px !important;
}
.activity .nav-line-tabs3 .nav-item .nav-link {
  width: 50px !important;
  position: relative !important;
}
.activity .nav-line-tabs1 .nav-item .nav-link.active {
  background: linear-gradient(180deg, #00c9ff 0.24%, #0059e1 96.59%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 50px !important;
}
.activity .nav-line-tabs1 .nav-item .nav-link {
  width: 50px !important;
  position: relative !important;
}
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
  z-index: 10000;
  height: 100%;
}
.cancel_button:hover {
  background-color: gray !important;
}

.loader-el {
  --origin-x: center;
  --origin-y: center;

  width: 8px;
  height: 8px;
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 4px);
  animation: spin 0.6s linear infinite;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    animation: spin 1s ease-out infinite;
    transform-origin: var(--origin-x) var(--origin-y);
    background: linear-gradient(rgb(0, 201, 255) 0.24%, rgb(0, 89, 225) 96.59%);
  }

  &:nth-child(1) {
    &::before {
      --origin-y: -100%;
    }

    &::after {
      --origin-y: 200%;
    }
  }

  &:nth-child(2) {
    &::before {
      --origin-x: -100%;
    }

    &::after {
      --origin-x: 200%;
    }
  }
}
@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
.ant-btn.ant-btn-icon-only {
  width: 31.5px !important;
}
.add {
  background: gray;
  opacity: 0.8;
  border-radius: 50px;
  color: #000;
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: none;
  align-items: center;
}
.add i {
  font-size: 32px;
  color: #cdced2;
}
.anticon {
  text-align: center;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 9px 13px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 17px;
}
.upload {
  background: rgba(0, 201, 255, 0.3);
  border-radius: 5px;
  border: 1px solid #00c9ff;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  padding: 8px;
  margin-left: 10px;
  // display: flex;
  // align-items: center;
  letter-spacing: 0.02em;
  color: #00c9ff;
}
.box__input {
  width: 100%;
  border: 1px solid grey;
  padding: 9px 11px 9px 11px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ant-space-item div {
  width: 100% !important;
}
.ant-space div:nth-child(1) {
  width: 50%;
}
.ant-space div:nth-child(2) {
  width: 50%;
}
.delete {
  background-color: #d5d1d1;
  // background: #f6a8a8;
  border-radius: 50%;
  padding: 3px;
  // font-size: 26px;
  border: none;
  height: 30px;
  width: 30px;
  color: #151515;
  // margin-top: 60px;
  // margin-left: -29px;
}
.delete i{
  color: red;
}

.cheackBox {
  margin-top: 40px;
}
.no_record {
  background-color: #ffc8c8;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  height: 50px;
  border-radius: 5px;
  color: #ff0000;
  font-size: 20px;
  font-weight: 700;
}
.empty-state {
  border-radius: 4px;
  &__content {
    padding: 48px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .empty-state__icon {
      width: 200px;
      height: 200px;
      display: flex;
      align-items: center;
      border-radius: 200px;
      justify-content: center;
      background-color: #f7fafc;
      // box-shadow: 0px 2px 1px #e1e3ec;
      img {
        width: 240px;
      }
    }
    .empty-state__message {
      color: #8a8a8a;
      font-size: 1.5rem;
      font-weight: 500;
      margin-top: 0.85rem;
    }
    .empty-state__help {
      color: #a2a5b9;
      font-size: 0.875rem;
    }
  }
}
.credit {
  color: #a2a5b9;
  font-size: 0.75rem;
  text-align: center;
  a {
    color: #444;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.nav-line-tabs1 .nav-item .nav-link.active:before {
  content: '';
  position: absolute;
  left: 8px;
  bottom: 0;
  height: 15px;
  width: 70%;
  border-bottom: 1px solid #009ef7;
}
.nav-line-tabs3 .nav-item .nav-link.active:before {
  content: '';
  position: absolute;
  /* left: 8px; */
  /* bottom: 0; */
  height: 53px;
  width: 70px;
  border-bottom: 1px solid #009ef7;
}
.profile {
  height: 45px;
  width: 100%;
  border: 1px solid #e0dada;
  padding: 9px 11px 9px 11px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pupload {
  background: rgba(0, 201, 255, 0.3);
  border-radius: 5px;
  border: 1px solid #00c9ff;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  padding: 4px;
  margin-left: 10px;
  // display: flex;
  // align-items: center;
  letter-spacing: 0.02em;
  color: #00c9ff;
}
.img_main {
  position: relative;
  overflow: hidden;
}
.img_second {
  width: 100px;
  height: 100px;
  overflow: hidden;
  display: inline-block;
}
.img_img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
html:not([amp4ads]),
html:not([amp4ads]) body {
  height: 100% !important;
}
